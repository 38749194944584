/**
 * @file FaxOrderLink
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname FaxOrderLink
 * @classdesc 
 */
export default class FaxOrderLink {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FaxOrderLink.constructor');
		this._$e = $e;
		this._$element = $('#fax-order').parent('dt');

		$e.on('click', (e) => this.onClick(e));
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof FaxOrderLink
	 * @return {undefined}
	 */
	onClick(e) {
		console.log('FaxOrderLink.onClick');
		e.preventDefault();

		if(this._$element.hasClass('open')){
			const maginTop = $(window).width() > 768 ? 130 : 0;
			$('html, body').scrollTop(this._$element.offset().top - maginTop);
		}else{
			this._$element.click();
		}
	}
}
