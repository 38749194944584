'use strict';

// utility class
import ApplyClass from '../Utils/ApplyClass';

import Form from '../Controller/Form';


import PriceCompare from '../View/Form/PriceCompare';
import ProductModal from '../View/Form/ProductModal';
import MailerSettings from '../View/Form/MailerSettings';


$(() => {
	const FormController = new Form();
	// 料金シミュレーション
	$('#setName').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(PriceCompare, $e, [$e, FormController]);
	});
	//たまごカートへ遷移
	$('.addCart').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ProductModal, $e, [$e, FormController]);
	});
	// メールのデフォルト設定
	$('.anchor--contact-mail').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(MailerSettings, $e, [$e, FormController]);
	});
});
