/**
 * @file 問い合わせのメーラー設定
 *
 */

 
 /**
  * @classname MailerSettings
  * @classdesc 問い合わせのメーラー設定
  */
 export default class MailerSettings {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('MailerSettings.constructor');
		this._$e = $e;
		const formId = $e.attr('data-form-id');

		this.contactMail = init(formId);
		this._$e.attr('href', this.contactMail);
		$e.on('click', () => this.onClick());
	};
 
	/**
	 * changeのコールバック
	 *
	 * @memberof MailerSettings
	 * @return {undefined}
	 */
	onClick() {
		console.log('MailerSettings.onClick');

	}
 }

function init(formId) {

	const lineFeed = "\r\n";

	const to = 'mailto:info@danitori.com';
	let subject = '';
	let body = '';
	console.log('to::' + to);

	// windows版ChromeかEdgeの場合は、総合フォーム（default）を表示
	const userAgent = navigator.userAgent;
	console.log('userAgent::' + userAgent);
	if (
		(userAgent.indexOf('Windows') !== -1 || 
		userAgent.indexOf('Win16') !== -1 || 
		userAgent.indexOf('Win32') !== -1 || 
		userAgent.indexOf('Win64') !== -1) 
		&& 
		userAgent.indexOf('Android') === -1
		&&
		(userAgent.indexOf('Chrome') !== -1 || userAgent.indexOf('Edg') !== -1)) {
		
		if(formId !== '5' && formId !== '7' ) {
			formId = '5';
		}
	}else if(
		(userAgent.indexOf('Windows') !== -1 || 
		userAgent.indexOf('Win16') !== -1 || 
		userAgent.indexOf('Win32') !== -1 || 
		userAgent.indexOf('Win64') !== -1) 
		&&
		userAgent.indexOf('Android') === -1
		&& 
		userAgent.indexOf('Firefox') !== -1 ) {
			if(formId === '1' ) {
				formId = '1-1';
			}
	}

	console.log('form id::' + formId)

	switch(formId) {
		case '1':
			subject = 'お問合せフォーム（定期購入お届けサイクル変更）';
			body = [
				'■ こちらのメールでは、定期購入のお届けサイクルの変更が可能です。',
				'■ 変更手続きをご希望の場合は、次回お届け予定日の20日前までに申請をお願いいたします。%0D%0A',
				'※次回お届け予定日20日前までのお届けサイクル変更は、マイページの定期購入注文履歴＞定期申込履歴詳細よりお手続きいただけます。',
				'https://www.danitori.com/account/previous_purchase%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'● お届けサイクル変更 ※該当するものに○をつけてください：',
				'[  ] 日付指定',
				'[  ] 曜日指定',
				'[  ] サイクル指定%0D%0A',
				'● 自由記入：%0D%0A%0D%0A%0D%0A',
				'例）日付指定の場合：毎回15日に届けてほしい',
				'例）曜日指定の場合：毎回第3土曜日に届けてほしい',
				'例）サイクル指定の場合：60日間隔で届けてほしい ※90日期間内での指定となります。%0D%0A',
				'※日付指定から曜日指定、曜日指定から日付指定への変更はできません。',
				'※サイクルは最長3ヶ月、または90日以内までご指定が可能です。それを超えてのサイクルはご指定いただけません。',
			];	
			break;

		case '1-1':
			subject = 'お問合せフォーム（定期購入お届けサイクル変更）';
			body = [
					'■ 変更手続きをご希望の場合は、次回お届け予定日の20日前までに申請をお願いいたします。%0D%0A',
					'※次回お届け予定日20日前までのお届けサイクル変更は、マイページの定期購入注文履歴＞定期申込履歴詳細よりお手続きいただけます。',
					'https://www.danitori.com/account/previous_purchase%0D%0A%0D%0A',
					'お名前 ※必須：',
					'フリガナ ※必須：',
					'電話番号 ※必須：',
					'メールアドレス ※必須：%0D%0A',
					'● お届けサイクル変更 ※該当するものに○をつけてください：',
					'[  ] 日付指定',
					'[  ] 曜日指定',
					'[  ] サイクル指定%0D%0A',
					'● 自由記入：%0D%0A%0D%0A%0D%0A',
					'例）日付指定の場合：毎回15日に届けてほしい',
					'例）曜日指定の場合：毎回第3土曜日に届けてほしい',
					'例）サイクル指定の場合：60日間隔で届けてほしい ※90日期間内での指定となります。%0D%0A',
					'※日付指定から曜日指定、曜日指定から日付指定への変更はできません。',
					'※サイクルは最長3ヶ月、または90日以内までご指定が可能です。それを超えてのサイクルはご指定いただけません。',
				];	
			break;

		case '2':
			subject = 'お問合せフォーム（定期購入お休み（スキップ））';
			body = [
				'■ こちらのメールでは、定期購入のお休み(スキップ)が可能です。',
				'■ 変更手続きをご希望の場合は、次回お届け予定日の20日前までに申請をお願いいたします。%0D%0A',
				'※次回お届け予定日20日前までのお休み(次回分スキップ)は、マイページの定期購入注文履歴＞定期申込履歴詳細よりお手続きいただけます。',
				'https://www.danitori.com/account/previous_purchase%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'● お休み(スキップ) ※該当するものに○をつけてください：',
				'[  ] 1回お休み',
				'[  ] 2回お休み',
				'※2回以上のお休みを希望される場合は、一旦定期購入をご解約いただき、必要なタイミングで改めて商品をご注文いただきますようお願いいたします。',
			];
			break;

		case '3':
			subject = 'お問合せフォーム（定期購入お届け希望日時変更）';
			body = [
				'■ こちらのメールでは、定期購入のお届け希望日時の変更が可能です。',
				'■ 変更手続きをご希望の場合は、次回お届け予定日の20日前までに申請をお願いいたします。%0D%0A',
				'※次回お届け予定日20日前までのお届け希望日時の変更は、マイページの定期購入注文履歴＞定期申込履歴詳細よりお手続きいただけます。',
				'https://www.danitori.com/account/previous_purchase%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'● お届け希望日時変更：',
				'[  ] 最短日を希望　※最短日を希望される方は○をつけてください%0D%0A',
				'● 次回お届け希望日：　　　月　　　日',
				'※次回お届け希望日がある方は、本日から8日以降〜6ヶ月(180日)以内でご記入ください。%0D%0A',
				'● 次回お届け希望時間帯 ※お届け希望時間帯がある方は、ご指定の時間帯に丸をつけてください。',
				'[  ] 時間指定なし',
				'[  ] AM',
				'[  ] 14-16時',
				'[  ] 16-18時 ',
				'[  ] 18-20時',
				'[  ] 19-20時',
				'※次回以降も同じ時間帯でのお届けとなります。'
			];
			break;

		case '4':
			subject = 'お問合せフォーム（住所変更）';
			body = [
				'■ こちらのメールでは、お届け先住所の変更が可能です。',
				'■ 変更手続きをご希望の場合は、次回お届け予定日の20日前までに申請をお願いいたします。%0D%0A',
				'※お客様情報の変更は、マイページの【会員情報の確認・変更】よりお手続きいただけます。',
				'https://www.danitori.com/account/change_profile%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'住所変更 ※該当するものに○をつけてください：',
				'[  ] 顧客情報・お届け先が同じ',
				'[  ] 顧客情報とお届け先が異なり、顧客情報のみ変更',
				'[  ] 顧客情報とお届け先が異なり、お届け先情報のみ変更%0D%0A%0D%0A',
				'郵便番号：〒      -',
				'都道府県：',
				'住所１(市区町村)：',
				'住所２(以降)：',
				'住所３(建物名)：'			
			];
			break;

		case '5':
			subject = 'お問合せフォーム';
			body = [
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'● お問合せ項目 ※該当するものに○をつけてください：',
				'[  ] 商品について',
				'[  ] 会員登録情報について',
				'[  ] ご注文について',
				'[  ] ダニについて',
				'[  ] その他%0D%0A%0D%0A',
				'● お問い合わせ（自由記入）：%0D%0A%0D%0A%0D%0A'
			];
			break;

		case '6':
			subject = 'お問合せフォーム（定期購入解約）';
			body = [
				'■ 解約手続きをご希望の場合は、次回お届け予定日の20日前までに申請をお願いいたします。',
				'■ 定期解約は、発送前のご注文のみ対象となります。',
				'■ お届け商品が発送準備に移っている場合は、次回お届け予定の定期便よりご解約いたします。%0D%0A%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'● 定期購入の解約理由 ※必須。該当するものに○をつけてください：',
				'[  ] 余っているため解約',
				'[  ] 経済的理由のため解約',
				'[  ] 効かない（効果不明）ため解約',
				'[  ] 受取辞退',
				'[  ] 転居、改装',
				'[  ] 変更（日時、商品）',
				'[  ] おまとめに移行',
				'※定期購入の解約を希望される方のみご入力ください。率直なご意見をお願いいたします。%0D%0A',
				'● その他の解約理由（自由記入）：%0D%0A%0D%0A%0D%0A',
				'※解約理由でその他をご選択した方はぜひ解約理由を教えてください。'
			];
			break;

		case '7':
			subject = 'お問合せフォーム（メールアドレス変更）';
			body = [
				'■ こちらのメールでは、メールアドレスの変更が可能です。%0D%0A',
				'※メールアドレスの変更は、マイページの【プロフィールの確認・変更】よりお手続きいただくことも可能です。',
				'https://www.danitori.com/account/previous_purchase%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'現在ご登録いただいているメールアドレス ※必須：',
				'変更後のメールアドレス ※必須：'
			];
			break;

		case '8':
			subject = 'お問合せフォーム（定期商品購入時のポイント利用）';
			body = [
				'■ こちらのメールでは、ポイント利用が可能です。',
				'■ ポイントの利用は、発送前のご注文のみ対象となります。',
				'■ お届け商品が発送準備に移っている場合は、次回お届け時にポイントを利用いたします。%0D%0A%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'● ポイント利用 ※該当するものに○をつけてください：',
				'[  ] 全てのポイントを利用する',
				'[  ] 一部ポイントを利用する%0D%0A%0D%0A',
				'● 一部ポイントの利用をご希望の方は、こちらに利用されるポイント数をご入力ください：',
			];
			break;

		case '9':
			subject = 'お問合せフォーム（お支払い方法変更）';
			body = [
				'■ こちらのメールでは、お支払い方法の変更が可能です。',
				'■ 変更手続きをご希望の場合は、次回お届け予定日の20日前までに申請をお願いいたします。',
				'※インターネットから注文されたお客様は、次回お届け予定日2週間前までであればマイアカウントより変更可能です。',
				'https://www.danitori.com/account/my_page_menu%0D%0A%0D%0A',
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：%0D%0A',
				'● お支払い方法を変更 ※該当するものに○をつけてください：',
				'[  ] 代金引換に変更',
				'[  ] クレジットカードに変更',
			];
			break;
	

		default:
			subject = 'お問合せフォーム';
			body = [
				'お名前 ※必須：',
				'フリガナ ※必須：',
				'電話番号 ※必須：',
				'メールアドレス ※必須：',
				'● お問合せ項目 ※該当するものに○をつけてください：',
				'[  ] 商品について',
				'[  ] 会員登録情報について',
				'[  ] ご注文について',
				'[  ] ダニについて',
				'[  ] その他%0D%0A',
				'● お問い合わせ（自由記入）：%0D%0A%0D%0A%0D%0A'
			];
			break;
	}
	

	let mail = to;
			mail += '?subject=' + subject;
			mail += '&body=' + body.join(encodeURIComponent(lineFeed));

	console.log('mail::' + mail.replace(/%0D%0A/g, ''));
	console.log('mail文字数::'+ subject + '::' + mail.replace(/%0D%0A/g, '').length);
	
	//console.log('mail::' + mail);
	return mail;

}