window.danitori = window.danitori || {};
window.danitori.const = window.danitori.const || {};

// Product Information
window.danitori.const.PRODUCT_INFO = {
	90077: {
		name1: "ダニ捕りロボソフト3個セット",
		name2: "（ラージサイズ1個・レギュラーサイズ2個）",
		catch: "ラージサイズ１個、レギュラーサイズ２個が入っています。",
		price: 5343,
		price2: "",
		price3: 4857,
		price4: "",
		itemId: "261=order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）１個",
			"ソフトケース（レギュラーサイズ）２個",
			"ラージサイズ用誘引マット１枚",
			"レギュラーサイズ用誘引マット２枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99077,
		upcellUrl: "",
		exchange: 90037,
		exchangeUrl: "/search/robo-exchange.html#p90037",
		luminous: true,
	},
	99077: {
		name1: "ダニ捕りロボソフト3個定期セット",
		name2: "（ラージサイズ1個・レギュラーサイズ2個）",
		catch: "初回にダニ捕りロボソフト3個セット（ラージサイズ1個・レギュラーサイズ2個）を、2回目以降交換時期の3ヶ月おきにダニ捕りロボ詰替3枚セット（ラージサイズ1枚・レギュラーサイズ2枚）を自動的にお届けします！",
		price: 5183,
		price2: 3446,
		price3: 4712,
		price4: 3133,
		itemId: "262=periodically_order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）１個（初回のみ）",
			"ソフトケース（レギュラーサイズ）２個（初回のみ）",
			"ラージサイズ用誘引マット１枚（毎回お届け）",
			"レギュラーサイズ用誘引マット２枚（毎回お届け）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		upcellUrl: "",
		exchange: 90037,
		exchangeUrl: "/search/robo-exchange.html#p90037",
		luminous: true,
	},
	90037: {
		name1: "ダニ捕りロボ詰替3枚セット",
		name2: "（ラージサイズ1枚・レギュラーサイズ2枚）",
		catch: "ダニ見つけて対策セットのダニ捕りロボ（ラージサイズ1個、レギュラーサイズ2個）専用の詰め替え商品です。",
		price: 3553,
		price2: "",
		price3: 3230,
		price4: "",
		itemId: "239=order",
		flow: "2",
		set: ["ラージサイズ用誘引マット1枚", "レギュラーサイズ用誘引マット2枚"],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
		],
		upcell: 50017,
		upcellUrl: "",
		exchange: "",
		exchangeUrl: "",
		luminous: true,
	},
	50017: {
		name1: "ダニ捕りロボ詰替3枚 定期セット",
		name2: "（ラージサイズ1枚・レギュラーサイズ2枚）",
		catch: "ダニ捕りロボ詰替（ラージサイズ1枚、レギュラーサイズ2枚）を交換時期の3ヶ月おきにを自動的にお届けします。",
		price: 3446,
		price2: "",
		price3: 3133,
		price4: "",
		itemId: "241=periodically_order",
		flow: "2",
		set: [
			"ラージサイズ用誘引マット1枚（毎回お届け）",
			"レギュラーサイズ用誘引マット2枚（毎回お届け）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		luminous: true,
	},
	40006: {
		name1: "ダニ見つけて対策セット",
		name2: "（ラージサイズ1個・レギュラーサイズ2個）",
		catch: "気になるところに置くだけでダニを発見できる新商品「ダニ目視キット」とダニを退治できる「ダニ捕りロボ」が一つになった特別セットが登場！",
		price: 6278,
		price2: "",
		price3: 5707,
		price4: "",
		itemId: "270=order",
		flow: "6",
		set: [
			"ソフトケース（ラージサイズ）1個",
			"ソフトケース（レギュラーサイズ）2個",
			"ラージサイズ用誘引マット1枚",
			"レギュラーサイズ用誘引マット2枚",
			"ダニ目視キット1個",
			"ルーペ1個",
			"マクロレンズ1個（スマホ観察用）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ目視キット",
				size: 'サイズ　：<br class="visible-xs-block">直径 70mm × 高さ 5mm',
				effect: "効果期間：3ヶ月間",
			},
		],
		upcell: 49006,
		upcellUrl: "",
		exchange: 90037,
		exchangeUrl: "",
	},
	49006: {
		name1: "ダニ見つけて対策 定期セット",
		name2: "（ラージサイズ1個・レギュラーサイズ2個）",
		catch: "気になるところに置くだけでダニを発見できる新商品「ダニ目視キット」とダニを退治できる「ダニ捕りロボ」の定期コースが一つになった特別セットが登場！初回にダニ目視キットとダニ捕りロボ（ラージサイズ1個、レギュラーサイズ2個）を、2回目以降交換時期の3ヶ月おきにダニ捕りロボ詰替（ラージサイズ1枚、レギュラーサイズ2枚）を自動的にお届けします。",
		price: 6090,
		price2: 3446,
		price3: 5536,
		price4: 3133,
		itemId: "269=periodically_order",
		flow: "6",
		set: [
			"ソフトケース（ラージサイズ）1個（初回のみ）",
			"ソフトケース（レギュラーサイズ）2個（初回のみ）",
			"ラージサイズ用誘引マット1枚（毎回お届け）",
			"レギュラーサイズ用誘引マット2枚（毎回お届け）",
			"ダニ目視キット1個（初回のみ）",
			"ルーペ1個（初回のみ）",
			"マクロレンズ1個（スマホ観察用・初回のみ）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ目視キット",
				size: 'サイズ　：<br class="visible-xs-block">直径 70mm × 高さ 5mm',
				effect: "効果期間：3ヶ月間",
			},
		],
		upcell: "",
		thumbnail_url: "/img/product/49006.jpg",
		exchange: 50017,
	},
	90009: {
		name1: "ダニ捕りロボ特選セット",
		name2: "（ラージサイズ2個・レギュラーサイズ3個）",
		catch: "人気No.1商品！ラージサイズ2個、レギュラーサイズ3個が入っています。",
		price: 8360,
		price2: "",
		price3: 7600,
		price4: "",
		itemId: "194=order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）2個",
			"ソフトケース（レギュラーサイズ）3個",
			"ラージサイズ用誘引マット2枚",
			"レギュラーサイズ用誘引マット3枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99009,
		upcellUrl: "",
		exchange: 90013,
		exchangeUrl: "",
		luminous: true,
	},
	190009: {
		name1: "A special set of Ninja ROBO（ダニ捕りロボ） ",
		name2: "(2 Large ones・3 Medium ones)",
		catch: "Best seller! It includes two large Ninja ROBOs and three medium Ninja ROBOs.",
		price: "",
		price2: "",
		price3: 8360,
		price4: "",
		itemId: "194=order",
		flow: "2",
		set: [
			"Soft large holder : 2",
			"Soft medium holder : 3",
			"Refill mat of Ninja ROBO（ダニ捕りロボ）for Large : 2",
			"Refill mat of Ninja ROBO（ダニ捕りロボ）for Medium : 3",
		],
		size: [
			{
				name: "Ninja ROBO（ダニ捕りロボ） : Medium/Soft",
				size: "L 150 mm × W 135 mm × T 8 mm",
				effect: "Effective period : within three months（after opened）※ Expiration period of unopened for two years.",
			},
			{
				name: "Ninja ROBO（ダニ捕りロボ） : Large/Soft",
				size: " L 185 mm × W 160 mm × T 8 mm",
				effect: "Effective period : within three months（after opened）※ Expiration period of unopened for two years.",
			},
		],
		upcell: 99009,
		upcellUrl: "",
		exchange: 190013,
		exchangeUrl: "/en/search/robo-exchange.html",
		cartUrl:
			"https://www.danitori.com/shop/add_to_cart?item_id_194=order&return_url=%2Fen%2Fsearch%2Frobo.html%23p190009",
	},
	290009: {
		name1: "忍者老宝（ダニ捕りロボ）特選套裝",
		name2: "（大尺寸2個・常規尺寸3個）",
		catch: "人氣最高的產品！套裝包括2個大尺寸和3個常規尺寸。",
		price: "",
		price2: "",
		price3: 8360,
		price4: "",
		itemId: "194=order",
		flow: "2",
		set: [
			"2個軟包（大尺寸）",
			"3個軟包（常規尺寸）",
			"2片大尺寸用引誘墊",
			"3片常規尺寸用引誘墊",
		],
		size: [
			{
				name: "忍者老宝（ダニ捕りロボ）軟包（常規尺寸）",
				size: '尺寸：<br class="visible-xs-block">長 150mm × 寬 135mm × 厚度 8mm',
				effect: "效果持續時間：3個月（引誘墊開封後）",
			},
			{
				name: "忍者老宝（ダニ捕りロボ）軟包（大尺寸）",
				size: '尺寸: <br class="visible-xs-block">長 185mm × 寬 165mm × 厚度 8mm',
				effect: "效果持續時間：3個月（引誘墊開封後）※未開封的保存期限為2年。",
			},
		],
		upcell: 99009,
		upcellUrl: "",
		exchange: 290013,
		exchangeUrl: "/tw/search/robo-exchange.html",
		cartUrl:
			"https://www.danitori.com/shop/add_to_cart?item_id_194=order&return_url=%2Ftw%2Fsearch%2Frobo.html%23p390009",
	},
	390009: {
		name1: "忍者老宝（ダニ捕りロボ）特选套装",
		name2: "（2个大尺寸・3个普通尺寸）",
		catch: "最受欢迎商品！本套装包含2个大尺寸和3个普通尺寸。",
		price: "",
		price2: "",
		price3: 8360,
		price4: "",
		itemId: "194=order",
		flow: "2",
		set: [
			"2个软外壳（大尺寸）",
			"3个软外壳（普通尺寸）",
			"2片大尺寸用引诱垫",
			"3片普通尺寸用引诱垫",
		],
		size: [
			{
				name: "忍者老宝（ダニ捕りロボ）软外壳（普通尺寸）",
				size: '尺寸：<br class="visible-xs-block">长 150mm × 宽 135mm × 厚 8mm',
				effect: "有效期限：3个月（引诱垫开封后）※未开封的保存期限为2年。",
			},
			{
				name: "忍者老宝（ダニ捕りロボ）软外壳（大尺寸）",
				size: '尺寸：<br class="visible-xs-block">长 185mm × 宽 165mm × 厚 8mm',
				effect: "有效期限：3个月（引诱垫开封后）※未开封的保存期限为2年。",
			},
		],
		upcell: 99009,
		upcellUrl: "",
		exchange: 390013,
		exchangeUrl: "/cn/search/robo-exchange.html",
		cartUrl:
			"https://www.danitori.com/shop/add_to_cart?item_id_194=order&return_url=%2Fcn%2Fsearch%2Frobo.html%23p390009",
	},
	99008: {
		name1: "ダニ捕りロボソフト3個定期セット",
		name2: "（レギュラーサイズ）",
		catch: "継続的にご使用いただくとより効果的に使用していただけます。初回にダニ捕りロボソフト3個セット（レギュラーサイズ）を、2回目以降交換時期の3ヶ月おきにダニ捕りロボ詰替用3枚セット（レギュラーサイズ）を自動的にお届けします!",
		// キャンペーン価格
		// price: 4802,
		// price2: 2988,
		// price3: 4365,
		// price4: 2716,
		// 通常価格
		price: 4979,
		price2: 2988,
		price3: 4525,
		price4: 2716,
		itemId: "271=periodically_order",
		flow: "2",
		set: [
			"ソフトケース（レギュラーサイズ）3個（初回のみ）",
			"レギュラーサイズ用誘引マット3枚（毎回お届け 計4回）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）",
			},
		],
		upcell: "",
		exchange: "90018",
		luminous: true,
	},
	99009: {
		name1: "ダニ捕りロボ特選定期セット",
		name2: "（ラージサイズ2個・レギュラーサイズ3個）",
		catch: "初回にダニ捕りロボ特選セットを、2回目以降交換時期の3ヶ月おきにダニ捕りロボ特選詰替用セットを自動的にお届けします。",
		price: 8109,
		price2: 5569,
		price3: 7372,
		price4: 5063,
		itemId: "195=periodically_order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）2個（初回のみ）",
			"ソフトケース（レギュラーサイズ）3個（初回のみ）",
			"ラージサイズ用誘引マット2枚（毎回お届け）",
			"レギュラーサイズ用誘引マット3枚（毎回お届け）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "90013",
		luminous: true,
	},
	// 90005: {
	// 	name1      : 'ダニ捕りロボ1000万個突破記念セット',
	// 	name2      : '（ラージサイズ2個・レギュラーサイズ3個）',
	// 	catch      : 'シリーズ累計販売個数1000万個突破を記念した商品です！',
	// 	price      : 8208,
	// 	price2     : '',
	// 	itemId     : '194=order',
	// 	flow       : '2',
	// 	set        : [
	// 		'ソフトケース（ラージサイズ）2個',
	// 		'ソフトケース（レギュラーサイズ）3個',
	// 		'ラージサイズ用誘引マット2枚',
	// 		'レギュラーサイズ用誘引マット3枚',
	// 	],
	// 	size       : [
	// 		{
	// 			name  : 'ダニ捕りロボソフト（レギュラーサイズ）',
	// 			size  : 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
	// 			effect: '効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。'
	// 		},
	// 		{
	// 			name  : 'ダニ捕りロボソフト（ラージサイズ）',
	// 			size  : 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
	// 			effect: '効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。'
	// 		},
	// 	],
	// 	upcell     : 99009,
	// 	upcellUrl  : '',
	// 	exchange   : 90013,
	// 	exchangeUrl: ''
	// },
	// 99005: {
	// 	name1   : 'ダニ捕りロボ1000万個突破記念定期セット',
	// 	name2   : '（ラージサイズ2個・レギュラーサイズ3個）',
	// 	catch   : '初回にダニ捕りロボ1000万個突破記念セットを、2回目以降交換時期の3ヶ月おきにダニ捕りロボソフト特選詰替用セットを自動的にお届けします。※ダニ捕りロボソフト特選詰替用セットはダニ捕りロボ1000万個突破記念セットに対応しています。',
	// 	price   : 7962,
	// 	price2  : 5468,
	// 	itemId  : '195=periodically_order',
	// 	flow    : '2',
	// 	set     : [
	// 		'ソフトケース（ラージサイズ）2個（初回のみ）',
	// 		'ソフトケース（レギュラーサイズ）3個（初回のみ）',
	// 		'ラージサイズ用誘引マット2枚（毎回お届け）',
	// 		'レギュラーサイズ用誘引マット3枚（毎回お届け）'
	// 	],
	// 	size    : [
	// 		{
	// 			name  : 'ダニ捕りロボソフト（レギュラーサイズ）',
	// 			size  : 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
	// 			effect: '効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。'
	// 		},
	// 		{
	// 			name  : 'ダニ捕りロボソフト（ラージサイズ）',
	// 			size  : 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
	// 			effect: '効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。'
	// 		},
	// 	],
	// 	upcell  : '',
	// 	exchange: ''
	// },
	// 90006: {
	// 	name1      : 'ダニ捕りロボソフト買いドキセット',
	// 	name2      : '（ラージサイズ2個・レギュラーサイズ3個・プチ2個組）',
	// 	catch      : '買いドキTVショッピングでOA中！！売れ筋No.1である「特選セット」にダニ捕りロボプチを2つプレゼントしての特別セットです！',
	// 	price      : 8208,
	// 	price2     : '',
	// 	itemId     : '189=order',
	// 	flow       : '2',
	// 	set        : [
	// 		'ソフトケース（ラージサイズ）2個',
	// 		'ソフトケース（レギュラーサイズ）3個',
	// 		'ラージサイズ用誘引マット2枚',
	// 		'レギュラーサイズ用誘引マット3枚',
	// 		'ダニ捕りロボプチ2個（プレゼント）'
	// 	],
	// 	size       : [
	// 		{
	// 			name  : 'ダニ捕りロボソフト（レギュラーサイズ）',
	// 			size  : 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
	// 			effect: '効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。'
	// 		},
	// 		{
	// 			name  : 'ダニ捕りロボソフト（ラージサイズ）',
	// 			size  : 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
	// 			effect: '効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。'
	// 		},
	// 		{
	// 			name  : 'ダニ捕りロボプチ',
	// 			size  : 'サイズ　：<br class="visible-xs-block">縦 115mm × 横 90mm × 幅10mm',
	// 			effect: '効果期間：3ヶ月間（開封後）※未開封の保存期間は２年間です。'
	// 		}
	// 	],
	// 	upcell     : 99006,
	// 	upcellUrl  : '',
	// 	exchange   : 90013,
	// 	exchangeUrl: ''
	// },
	90005: {
		name1: "ダニ捕りロボソフト特選セット",
		name2: "（ラージサイズ2個・レギュラーサイズ3個）",
		//catch   : 'ラージサイズとレギュラーサイズの組み合わせだから、ベッドからソファー、カーペットまで、気になる場所のダニ対策が一気にできる売れ筋No.1のセットです!',
		catch: "人気No.1商品！ラージサイズ2個、レギュラーサイズ3個が入っています。",
		price: 7560,
		price2: "",
		itemId: "5=order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）2個",
			"ソフトケース（レギュラーサイズ）3個",
			"ラージサイズ用誘引マット2枚",
			"レギュラーサイズ用誘引マット3枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99005,
		exchange: 90013,
	},
	90003: {
		name1: "ダニ捕りロボソフト5個セット",
		name2: "（ラージサイズ）",
		catch: "ベッド、ソファー、ラグ、カーペットのダニ対策ができるラージサイズが5個入ったセットです!",
		price: 9350,
		price2: "",
		price3: 8500,
		price4: "",
		itemId: "3=order",
		flow: "2",
		set: ["ソフトケース（ラージサイズ）5個", "ラージサイズ用誘引マット5枚"],
		size: [
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です!",
			},
		],
		upcell: 99003,
		exchange: 90012,
		luminous: true,
	},
	90002: {
		name1: "ダニ捕りロボソフト5個セット",
		name2: "（レギュラーサイズ）",
		catch: "シングルベッド、子どもベッド、ソファー、押入れ、クローゼットのダニ対策ができるレギュラーサイズが5個入ったセットです!",
		price: 8250,
		price2: "",
		price3: 7500,
		price4: "",
		itemId: "2=order",
		flow: "2",
		set: [
			"ソフトケース（レギュラーサイズ）5個",
			"レギュラーサイズ用誘引マット5枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99002,
		exchange: 90011,
		luminous: true,
	},
	90008: {
		name1: "ダニ捕りロボソフト３個セット",
		name2: "（レギュラーサイズ）",
		catch: "初めてのお客様におすすめするセット商品です。シングルベッド、子どもベッド、ソファー、押入れ、クローゼットのダニ対策ができるレギュラーサイズが3個入ったセット商品です!",
		// キャンペーン価格
		// price: 4950,
		// price2: "",
		// price3: 4500,
		// price4: "",
		// 通常価格
		price: 5133,
		price2: "",
		price3: 4666,
		price4: "",
		itemId: "6=order",
		flow: "2",
		set: [
			"ソフトケース（レギュラーサイズ）3個",
			"レギュラーサイズ用誘引マット3枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99008,
		exchange: 90018,
		luminous: true,
	},
	90010: {
		name1: "ダニ捕りロボソフト3個セット",
		name2: "（ラージサイズ）",
		catch: "初めてのお客様におすすめするセット商品です。シングルベッド、子どもベッド、ソファー、押入れ、クローゼットのダニ対策ができるラージサイズが3個入ったセット商品です!",
		// キャンペーン価格
		// price: 4950,
		// price2: "",
		// price3: 4500,
		// price4: "",
		// 通常価格
		price: 5610,
		price2: 4224,
		price3: "",
		price4: "",
		itemId: "308=order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）3個",
			"ラージサイズ用誘引マット3枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99010,
		exchange: 90015,
		luminous: true,
	},
	99010: {
		name1: "ダニ捕りロボソフト3個セット",
		name2: "（ラージサイズ）",
		catch: "初めてのお客様におすすめするセット商品です。シングルベッド、子どもベッド、ソファー、押入れ、クローゼットのダニ対策ができるラージサイズが3個入ったセット商品です!",
		// キャンペーン価格
		// price: 4950,
		// price2: "",
		// price3: 4500,
		// price4: "",
		// 通常価格
		price: 5442,
		price2: 4097,
		price3: "",
		price4: "",
		itemId: "309=order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）3個",
			"ラージサイズ用誘引マット3枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99010,
		exchange: 90010,
		luminous: true,
	},
	90015: {
		name1: "ダニ捕りロボ詰替用3枚セット",
		name2: "（ラージサイズ）",
		catch: "すでにダニ捕りロボソフト３個セット（ラージサイズ）を購入し、継続的なご使用には詰替用の誘引マットのセットがおすすめです!",
		price: 4224,
		price2: "4224",
		price3: "",
		price4: "",
		itemId: "252=order",
		flow: "2",
		set: ["ラージサイズ用誘引マット3枚"],
		size: [
			{
				name: "ラージサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 160mm × 横 132mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 50015,
		exchange: "",
		luminous: true,
	},
	50015: {
		name1: "ダニ捕りロボ詰替用3枚セット",
		name2: "（ラージサイズ）",
		catch: "すでにダニ捕りロボソフト３個セット（ラージサイズ）を購入し、継続的なご使用には詰替用の誘引マットのセットがおすすめです!",
		price: 4097,
		price2: "",
		price3: 4097,
		price4: "",
		itemId: "253=order",
		flow: "2",
		set: ["ラージサイズ用誘引マット3枚"],
		size: [
			{
				name: "ラージサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 160mm × 横 132mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 50015,
		exchange: "",
		luminous: true,
	},
	90001: {
		name1: "ダニ捕りロボハード5個セット",
		name2: "（レギュラーサイズ）",
		catch: "ペットハウス、タンス、クローゼット、押入れ、衣装ケース、お車などのダニ対策ができるダニ捕りロボのハードタイプ5個セットです!",
		price: 7700,
		price2: "",
		price3: 7000,
		price4: "",
		itemId: "1=order",
		flow: "3",
		set: [
			"ハードケース（レギュラーサイズ）5個",
			"レギュラーサイズ用誘引マット5枚",
		],
		size: [
			{
				name: "ダニ捕りロボハード（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 125mm × 横 105mm × 幅12mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: 90011,
	},
	90026: {
		name1: "ダニ捕りロボプチ10個セット",
		name2: "",
		catch: "バッグ、ブーツ、衣装ケースなどのダニ対策ができるダニ捕りロボプチ10個セットです!",
		price: 6915,
		price2: "",
		price3: 6286,
		price4: "",
		itemId: "91=order",
		flow: "4",
		set: ["ダニ捕りロボプチ10個"],
		size: [
			{
				name: "ダニ捕りロボプチ",
				size: 'サイズ　：<br class="visible-xs-block">縦 115mm × 横 90mm × 幅10mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 99026,
		exchange: "",
		additionalInfo: "roboputiSpecChange",
	},
	90027: {
		name1: "ダニ捕りロボ　はじめてのダニ対策セット",
		name2: "（レギュラーサイズ2個・プチ2個）",
		catch: "初めてダニ捕りロボをご使用のお客様におすすめ！シングルベッド、子どもベッド、ソファー、押入れ、クローゼットのダニ対策ができるレギュラーサイズ2個と、バッグ、ブーツ、衣装ケースのダニ対策ができるプチサイズ2個入ったセット商品です!",
		price: 4684,
		price2: "",
		price3: 4258,
		price4: "",
		itemId: "139=order",
		flow: "5",
		set: [
			"ソフトケース（レギュラーサイズ）2個",
			"ダニ捕りロボプチ2個",
			"レギュラーサイズ用誘引マット2枚",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボプチ",
				size: 'サイズ　：<br class="visible-xs-block">縦 115mm × 横 90mm × 幅10mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		additionalInfo: "roboputiSpecChange",
	},
	90013: {
		name1: "ダニ捕りロボソフト特選詰替用セット",
		name2: "（ラージサイズ2枚・レギュラーサイズ3枚）",
		addInfo: "",
		catch: "すでにダニ捕りロボソフト特選セットを購入し、継続的なご使用には詰替用の誘引マットのセットがおすすめです!",
		price: 5741,
		price2: "",
		price3: 5219,
		price4: "",
		itemId: "9=order",
		flow: "2",
		set: ["ラージサイズ用誘引マット2枚", "レギュラーサイズ用誘引マット3枚"],
		size: [
			{
				name: "レギュラーサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 100mm × 横 100mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
			{
				name: "ラージサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 160mm × 横 132mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 50013,
		exchange: "",
		luminous: true,
	},
	190013: {
		name1: "A special refill set of Ninja ROBO（ダニ捕りロボ） ",
		name2: "(2 Large mats・3 Medium mats)",
		addInfo: "",
		catch: "This set is suitable for continuous use that already has a special set of Ninja ROBO.",
		price: "",
		price2: "",
		price3: 5741,
		price4: "",
		itemId: "9=order",
		flow: "2",
		set: [
			"Refill mat of Ninja ROBO（ダニ捕りロボ）for Large : 2",
			"Refill mat of Ninja ROBO（ダニ捕りロボ）for Medium : 3",
		],
		size: [
			{
				name: "Refill mat for Medium",
				size: " L 100 mm × W 100 mm",
				effect: "Effective period : within three months（after opened）※ Expiration period of unopened for two years.",
			},
			{
				name: "Refill mat for Large",
				size: " L 160 mm × W 132 mm",
				effect: "Effective period : within three months（after opened）<br>※ Expiration period of unopened for two years.",
			},
		],
		upcell: "",
		exchange: "",
		cartUrl:
			"https://www.danitori.com/shop/add_to_cart?item_id_9=order&return_url=%2Fen%2Fsearch%2Frobo.html%23p190013",
	},
	290013: {
		name1: "忍者老宝（ダニ捕りロボ）軟包特選替換套裝",
		name2: "（大尺寸2片・常規尺寸3片）",
		addInfo: "",
		catch: "如果您已購買忍者老宝（ダニ捕りロボ）軟包特選套裝，並有持續使用的意願，推薦您購買替換用的引誘墊套裝。",
		price: "",
		price2: "",
		price3: 5741,
		price4: "",
		itemId: "9=order",
		flow: "2",
		set: ["大尺寸用引誘墊2片", "常規尺寸用引誘墊3片"],
		size: [
			{
				name: "常規尺寸用引誘墊",
				size: '尺寸：<br class="visible-xs-block">長 100mm × 寬 100mm',
				effect: "・效果持續時間：3個月（引誘墊開封後）<br>※未開封的保存期限為2年。",
			},
			{
				name: "大尺寸用引誘墊",
				size: '尺寸：<br class="visible-xs-block">長 160mm × 寬 132mm',
				effect: "效果持續時間：3個月（引誘墊開封後）<br>※未開封的保存期限為2年。",
			},
		],
		upcell: "",
		exchange: "",
		cartUrl:
			"https://www.danitori.com/shop/add_to_cart?item_id_9=order&return_url=%2Ftw%2Fsearch%2Frobo.html%23p290013",
	},
	390013: {
		name1: "忍者老宝（ダニ捕りロボ）软外壳特选替换套装",
		name2: "（2片大尺寸・3片普通尺寸）",
		addInfo: "",
		catch: "如果您已购买忍者老宝（ダニ捕りロボ）软外壳特选套装，并想继续使用的话，建议您购买替换用的引诱垫套装。",
		price: "",
		price2: "",
		price3: 5741,
		price4: "",
		itemId: "9=order",
		flow: "2",
		set: ["2片大尺寸用引诱垫", "3片普通尺寸用引诱垫"],
		size: [
			{
				name: "普通尺寸用引诱垫",
				size: '尺寸：<br class="visible-xs-block">长 100mm × 宽 100mm',
				effect: "有效期限：3个月（引诱垫开封后）<br>※未开封的保存期限为2年。",
			},
			{
				name: "大尺寸用引诱垫",
				size: '尺寸：<br class="visible-xs-block">长 160mm × 宽 132mm',
				effect: "有效期限：3个月（引诱垫开封后）<br>※未开封的保存期限为2年。",
			},
		],
		upcell: "",
		exchange: "",
		cartUrl:
			"https://www.danitori.com/shop/add_to_cart?item_id_9=order&return_url=%2Fcn%2Fsearch%2Frobo.html%23p390013",
	},
	90018: {
		name1: "ダニ捕りロボ詰替用3枚セット",
		name2: "（レギュラーサイズ）",
		catch: "すでにダニ捕りロボソフト３個セット（レギュラーサイズ）を購入し、継続的なご使用には詰替用の誘引マットのセットがおすすめです!",
		price: 3080,
		price2: "",
		price3: 2800,
		price4: "",
		itemId: "10=order",
		flow: "2",
		set: ["レギュラーサイズ用誘引マット3枚"],
		size: [
			{
				name: "レギュラーサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 100mm × 横 100mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 50018,
		exchange: "",
		luminous: true,
	},
	90011: {
		name1: "ダニ捕りロボ詰替用5枚セット",
		name2: "（レギュラーサイズ）",
		catch: "すでにダニ捕りロボソフト5個セット（レギュラーサイズ）、もしくはダニ捕りロボハード5個セット（レギュラーサイズ）を購入し、継続的なご使用には詰替用の誘引マットのセットがおすすめです!",
		price: 5134,
		price2: "",
		price3: 4667,
		price4: "",
		itemId: "7=order",
		flow: "2",
		set: ["レギュラーサイズ用誘引マット5枚"],
		size: [
			{
				name: "レギュラーサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 100mm × 横 100mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 50011,
		exchange: "",
		luminous: true,
	},
	90012: {
		name1: "ダニ捕りロボ詰替用5枚セット",
		name2: "（ラージサイズ）",
		catch: "すでにダニ捕りロボソフト5個セット（ラージサイズ）を購入し、継続的なご使用には詰替用の誘引マットのセットがおすすめです!",
		price: 7040,
		price2: "",
		price3: 6400,
		price4: "",
		itemId: "8=order",
		flow: "2",
		set: ["ラージサイズ用誘引マット5枚"],
		size: [
			{
				name: "ラージサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 160mm × 横 132mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 50012,
		exchange: "",
		luminous: true,
	},
	80005: {
		name1: "ダニ捕りマット小サイズ2枚セット",
		name2: "",
		catch: "初めてダニ捕りマットをご使用のお客様におすすめ！ソファ、畳、ベッド、押入れ、ラグなどのダニ対策ができるダニ捕りマット小サイズ2枚セットです!",
		price: 3178,
		price2: "",
		price3: 2889,
		price4: "",
		itemId: "129=order",
		flow: "1",
		set: ["ダニ捕りマット小サイズ2枚"],
		size: [
			{
				name: "ダニ捕りマット小サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 160mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
	},
	80006: {
		name1: "ダニ捕りマット小サイズ4枚セット",
		name2: "",
		catch: "ダニ捕りマット小サイズ2枚セットより単品のお値段が少しお安くなっております。ソファ、畳、ベッド、押入れ、ラグなどのダニ対策ができるダニ捕りマット小サイズ4枚セットです!",
		price: 6112,
		price2: "",
		price3: 5556,
		price4: "",
		itemId: "130=order",
		flow: "1",
		set: ["ダニ捕りマット小サイズ4枚"],
		size: [
			{
				name: "ダニ捕りマット小サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 160mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 80008,
		exchange: "",
	},
	80007: {
		name1: "ダニ捕りマット小サイズ10枚セット",
		name2: "",
		catch: "ダニ捕りマット小サイズ4枚セットより単品のお値段がお安くなっております。ソファ、畳、ベッド、押入れ、ラグなどのダニ対策ができるダニ捕りマット小サイズ10枚セットです!",
		price: 14259,
		price2: "",
		price3: 12963,
		price4: "",
		itemId: "131=order",
		flow: "1",
		set: ["ダニ捕りマット小サイズ10枚"],
		size: [
			{
				name: "ダニ捕りマット小サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 160mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
	},
	80015: {
		name1: "ダニ捕りマット大サイズ2枚セット",
		name2: "",
		catch: "初めてダニ捕りマットをご使用のお客様におすすめ！ソファ、畳、ベッド（大きいサイズ）、カーペット、ラグなどのダニ対策ができるダニ捕りマット大サイズ2枚セットです!",
		price: 3850,
		price2: "",
		price3: 3500,
		price4: "",
		itemId: "132=order",
		flow: "1",
		set: ["ダニ捕りマット大サイズ2枚"],
		size: [
			{
				name: "ダニ捕りマット大サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 295mm × 横 210mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
	},
	80016: {
		name1: "ダニ捕りマット大サイズ4枚セット",
		name2: "",
		catch: "ダニ捕りマット大サイズ2枚セットより単品のお値段がお安くなっております。畳、ベッド（大きいサイズ）、カーペット、ラグなどのダニ対策ができるダニ捕りマット大サイズ4枚セットです!",
		price: 7480,
		price2: "",
		price3: 6800,
		price4: "",
		itemId: "133=order",
		flow: "1",
		set: ["ダニ捕りマット大サイズ4枚"],
		size: [
			{
				name: "ダニ捕りマット大サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 295mm × 横 210mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: 80018,
		exchange: "",
	},
	80017: {
		name1: "ダニ捕りマット大サイズ10枚セット",
		name2: "",
		catch: "ダニ捕りマット大サイズ4枚セットより単品のお値段がお安くなっております。畳、ベッド（大きいサイズ）、カーペット、ラグなどのダニ対策ができるダニ捕りマット大サイズ10枚セットです!",
		price: 17600,
		price2: "",
		price3: 16000,
		price4: "",
		itemId: "134=order",
		flow: "1",
		set: ["ダニ捕りマット大サイズ10枚"],
		size: [
			{
				name: "ダニ捕りマット大サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 295mm × 横 210mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
	},
	99006: {
		name1: "ダニ捕りロボソフト買いドキ定期セット",
		name2: "ラージ2個・レギュラー3個セット　※初回にダニ捕りロボプチ2個プレゼント）",
		catch: "継続的にご使用いただくとより効果的に使用していただけます。初回にダニ捕りロボソフト買いドキセットを、2回目以降交換時期の3ヶ月おきにダニ捕りロボソフト特選詰替用セットを自動的にお届けします。",
		price: 7962,
		price2: 5468,
		itemId: "188=periodically_order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）2個（初回のみ）",
			"ソフトケース（レギュラーサイズ）3個（初回のみ）",
			"ダニ捕りロボプチ2個（初回のみ）",
			"ラージサイズ用誘引マット2枚（毎回お届け）",
			"レギュラーサイズ用誘引マット3枚（毎回お届け）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボプチ",
				size: 'サイズ　：<br class="visible-xs-block">縦 115mm × 横 90mm × 幅10mm',
				effect: "効果期間：3ヶ月間（開封後）※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
	},
	99005: {
		name1: "ダニ捕りロボソフト特選定期セット",
		name2: "（ラージ2個・レギュラー3個）",
		catch: "初回にダニ捕りロボ特選セットを、2回目以降交換時期の3ヶ月おきにダニ捕りロボ特選詰替用セットを自動的にお届けします。",
		price: 7333,
		price2: 5468,
		itemId: "15=periodically_order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）2個（初回のみ）",
			"ソフトケース（レギュラーサイズ）3個（初回のみ）",
			"ラージサイズ用誘引マット2枚（毎回お届け 計4回）",
			"レギュラーサイズ用誘引マット3枚（毎回お届け 計4回）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "※定期コースは4回以上の継続がお約束となります。",
	},
	99002: {
		name1: "ダニ捕りロボソフト5個定期セット",
		name2: "（レギュラーサイズ）",
		catch: "初回にダニ捕りロボソフト5個セット（レギュラーサイズ）を、2回目以降交換時期の3ヶ月おきにダニ捕りロボ詰替用5枚セット（レギュラーサイズ）を自動的にお届けします!",
		price: 8003,
		price2: 4980,
		price3: 7275,
		price4: 4527,
		itemId: "12=periodically_order",
		flow: "2",
		set: [
			"ソフトケース（レギュラーサイズ）5個（初回のみ）",
			"レギュラーサイズ用誘引マット5枚（毎回お届け 計4回）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（レギュラーサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 150mm × 横 135mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
		luminous: true,
	},
	99003: {
		name1: "ダニ捕りロボソフト5個定期セット",
		name2: "（ラージサイズ）",
		catch: "継続的にご使用いただくとより効果的に使用していただけます。初回にダニ捕りロボソフトセット（ラージサイズ）を、2回目以降交換時期の3ヶ月おきにダニ捕りロボ詰替用5枚セット（ラージサイズ）を自動的にお届けします!",
		price: 9070,
		price2: 6829,
		price3: 8245,
		price4: 6208,
		itemId: "13=periodically_order",
		flow: "2",
		set: [
			"ソフトケース（ラージサイズ）5個（初回のみ）",
			"ラージサイズ用誘引マット5枚（毎回お届け 計4回）",
		],
		size: [
			{
				name: "ダニ捕りロボソフト（ラージサイズ）",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 165mm × 幅8mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
		luminous: true,
	},
	99026: {
		name1: "ダニ捕りロボプチ10個定期セット",
		name2: "",
		catch: "ダニ捕りロボプチ10個セットを交換時期の3ヶ月おきに自動的にお届けします!",
		price: 6707,
		price2: "",
		price3: 6097,
		price4: "",
		itemId: "92=periodically_order",
		flow: "4",
		set: ["ダニ捕りロボプチ10個（毎回お届け 計4回）"],
		size: [
			{
				name: "ダニ捕りロボプチ",
				size: 'サイズ　：<br class="visible-xs-block">縦 115mm × 横 90mm × 幅10mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
		additionalInfo: "roboputiSpecChange",
	},
	50013: {
		name1: "ダニ捕りロボソフト特選詰替定期セット",
		name2: "（ラージサイズ2枚・レギュラーサイズ3枚セット）",
		catch: "ダニ捕りロボソフト特選詰替用セットを交換時期の3ヶ月おきに自動的にお届けします!",
		price: 5569,
		price2: "",
		price3: 5063,
		price4: "",
		itemId: "18=periodically_order",
		flow: "2",
		set: [
			"ラージサイズ用誘引マット2枚（毎回お届け 計4回）",
			"レギュラーサイズ用誘引マット3枚（毎回お届け 計4回）",
		],
		size: [
			{
				name: "レギュラーサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 100mm × 横 100mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
			{
				name: "ラージサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 160mm × 横 132mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
		luminous: true,
	},
	50011: {
		name1: "ダニ捕りロボ詰替5枚定期セット",
		name2: "（レギュラーサイズ）",
		catch: "ダニ捕りロボ詰替用5枚セット（レギュラーサイズ）を交換時期の3ヶ月おきに自動的にお届けします!",
		price: 4980,
		price2: "",
		price3: 4527,
		price4: "",
		itemId: "16=periodically_order",
		flow: "2",
		set: ["レギュラーサイズ用誘引マット5枚（毎回お届け 計4回）"],
		size: [
			{
				name: "レギュラーサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 100mm × 横 100mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
		luminous: true,
	},
	50012: {
		name1: "ダニ捕りロボ詰替5枚定期セット",
		name2: "（ラージサイズ）",
		catch: "ダニ捕りロボ詰替用5枚セット（ラージサイズ）を交換時期の3ヶ月おきに自動的にお届けします!",
		price: 6829,
		price2: "",
		price3: 6208,
		price4: "",
		itemId: "17=periodically_order",
		flow: "2",
		set: ["ラージサイズ用誘引マット5枚（毎回お届け 計4回）"],
		size: [
			{
				name: "ラージサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 160mm × 横 132mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
		luminous: true,
	},
	50018: {
		name1: "ダニ捕りロボ詰替3枚定期セット",
		name2: "（レギュラーサイズ）",
		catch: "ダニ捕りロボ詰替用5枚セット（レギュラーサイズ）を交換時期の3ヶ月おきに自動的にお届けします!",
		price: 2988,
		price2: "",
		price3: 2716,
		price4: "",
		itemId: "225=periodically_order",
		flow: "2",
		set: ["レギュラーサイズ用誘引マット3枚（毎回お届け 計4回）"],
		size: [
			{
				name: "レギュラーサイズ用誘引マット",
				size: 'サイズ　：<br class="visible-xs-block">縦 100mm × 横 100mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
		luminous: true,
	},
	80008: {
		name1: "ダニ捕りマット小サイズ4枚定期セット",
		name2: "",
		catch: "ダニ捕りマット小サイズ4枚セットを交換時期の3ヶ月おきに自動的にお届けします!",
		price: 5928,
		price2: "",
		price3: 5389,
		price4: "",
		itemId: "136=periodically_order",
		flow: "1",
		set: ["ダニ捕りマット小サイズ4枚（毎回お届け 計4回）"],
		size: [
			{
				name: "ダニ捕りマット小サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 185mm × 横 160mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
	},
	80018: {
		name1: "ダニ捕りマット大サイズ4枚定期セット",
		name2: "",
		catch: "ダニ捕りマット大サイズ4枚セットを交換時期の3ヶ月おきに自動的にお届けします!",
		price: 7256,
		price2: "",
		price3: 6596,
		price4: "",
		itemId: "135=periodically_order",
		flow: "1",
		set: ["ダニ捕りマット大サイズ4枚（毎回お届け 計4回）"],
		size: [
			{
				name: "ダニ捕りマット大サイズ",
				size: 'サイズ　：<br class="visible-xs-block">縦 295mm × 横 210mm × 幅5mm',
				effect: "効果期間：3ヶ月間（誘引マットの開封後）<br>※未開封の保存期間は２年間です。",
			},
		],
		upcell: "",
		exchange: "",
		attentionText: "",
	},
};

// 使用方法テキスト
window.danitori.const.FLOW_TEXT = {
	flow1: [
		"",
		"ダニ捕りマットを取り出します。",
		"日付シールに設置日を記入してください。",
		"3ヶ月間設置してください。",
		"3ヵ月たちましたら捨てていただき、新しいダニ捕りマットと交換してください。",
	],
	flow2: [
		"",
		"中身の誘引マットを取り出します。",
		"本体ケースに誘引マットを入れます。",
		"付属の日付シールに設置日を記入して、本体ケースに貼ってください。",
		"3ヶ月間、ダニ捕りロボを置くだけ！",
	],
	flow3: [
		"",
		"中身の誘引マットを取り出します。",
		"本体ケースに誘引マットを入れます。",
		"ペットハウスや押入れに設置してください。",
		"３か月間、ダニ捕りロボを置くだけ！<br>３か月後に中身の誘引マットを袋に入れてゴミ箱に捨ててください。",
	],
	flow4: [
		"",
		"中身のダニ捕りロボプチを取り出します。",
		"日付シールに設置日を記入してください。",
		"タンスや衣装ケース食料庫、ブーツ<br>気になるところにポンッと置くだけ。",
		"３ヶ月後に袋に入れてゴミ箱に捨ててください。",
	],
};

// 任意の追記
// ロボプチ仕様変更文言
window.danitori.const.ADDITIONAL_INFO = {
	roboputiSpecChange: {
		title: "ダニ捕りロボプチ 仕様変更のご案内",
		text:
			"「ダニ捕りロボ　プチ」製品生地の生産終了に伴い、製品生地を変更致しました。製品性能、価格、サイズに変更はございません。ご注文のタイミングによってお届けする商品の生地が異なりますので、予めご了承いただきますよう宜しくお願い致します。<br>（変更時期は、2020年6月上旬から随時）" +
			'<p class="tac"><img src="/img/product/roboputiSpecChange.jpg" alt="プチロボ仕様変更"></p>',
	},
};
