/**
 * @file 
 */
'use strict';


/**
 * @classname FormMailer
 * @classdesc FormMailer
 */
export default class FormMailer {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormMailer.constructor');
		this._$e = $e;
		this._$iframe = this._$e.find('iframe.formmailer');
		this._iframeNumber = 0;
		window.addEventListener('message', (e) => this.onMessage(e));	

		$(window).on('resize', function(){
			setTimeout(function(){
				this._$iframe.height($iframe.contents().find('html').innerHeight());
			},2000);
		});

	};

	/**
	 * messageのコールバック
	 *
	 * @memberof Dropdown
	 * @return {undefined}
	 */
	onMessage(e) {
		console.log('FormMailer.onMessage');
		const eventName = e.data[0];
		const data = e.data[1];
		let iframeHeight = 500;
		if (eventName === 'getData') {
			if (this._iframeNumber !== 0) {
				let tmpHeight = 0;
				for (let i = 0; i < this._iframeNumber; i++) {
					tmpHeight += data.params.height + 4;
				}
				iframeHeight = tmpHeight;
			}
			console.log(iframeHeight);
			this._$iframe.height(iframeHeight);
		}
		if (eventName === 'getElements') {
			this._iframeNumber = data;
		}
	};

}
