/**
 * @file ページネーション
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname Pagination
 * @classdesc ページネーション
 */
export default class Pagination {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Pagination.constructor');
		this._$e = $e;
		this._$target = $(`.${$e.parents('.block--pagination').attr('data-target')}`);
		this._$targetList = this._$target.find('.list-item');

		this._$itemsPerPage = 10; // ページに表示されるアイテム数
		this._$currentPage = 1; // 現在表示されているページ番号
		this._$numPages = Math.ceil(this._$targetList.length / this._$itemsPerPage); // ページ数を計算する
		this._$pageLinks = this._$e.siblings('.anchor--page'); // ページリンクの配列を取得する

		this.showItems(1);

		$e.on('click', (e) => this.onClick(e));
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof Pagination
	 * @return {undefined}
	 */
	onClick(e) {
		console.log('Pagination.onClick');
		e.preventDefault();
		let page = this._$e.text();
		$('.block--pagination .active').removeClass('active');
		this._$e.addClass('active');
		this.showItems(page);

		const maginTop = $(window).width() > 768 ? 140 : 10;
		$('html, body').scrollTop(this._$target.offset().top - maginTop);

	};

	showItems(page) {
		let startIndex = (page - 1) * this._$itemsPerPage;
		let endIndex = startIndex + this._$itemsPerPage;
		this._$targetList.hide();
		this._$targetList.slice(startIndex, endIndex).show();
	};

}


