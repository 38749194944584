/**
 * @file Focus
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname Focus
 * @classdesc faqページでアコーディオンがアンカー指定されていたら、そのdtをopenする
 */
export default class Focus {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Focus.constructor');
		this._$e = $e;

		const url = new URL(window.location.href);
		const anchor = url.hash;

		this._$element = $('#fax-order').parent('dt');

		if(anchor == '#fax-order') {
			this.openDt();
		}
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof Focus
	 * @return {undefined}
	 */
	openDt() {
		console.log('Focus.openDt');
		this._$element.click();
	}
}
