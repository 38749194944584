/**
 * @file Utils/ApplyClass.js
 */

export default class ApplyClass {
  /**
   * クラスを要素に適用する
   *
   * memo
   * http://stackoverflow.com/questions/1606797/use-of-apply-with-new-operator-is-this-possible/#answer-1608546
   *
   * @member ApplyClass
   * @param {function} klass - 適用したいクラス
   * @param {jQuery} $e - クラスを適用する jQuery オブジェクト
   * @param {Array<Object>} args - クラスのインスタンスを生成するときに渡す引数
   * @return {Object} instance - 生成したクラスのインスタンス
   */
  static apply(klass, $e, args) {
    this.hasApplied(klass, $e);
    if (this.hasApplied(klass, $e)) {
      return;
    }
    const F = function () {
      return klass.apply(this, args);
    };
    F.prototype = klass.prototype;
    const instance = new F();

    if (instance) {
      $e.data(name, true);
      $e.data('_' + name, instance)
    }
    return instance;
  };


  /**
   * 要素にクラスが適用されているか調べる
   *
   * @member ApplyClass
   * @param {function} klass - 適用したいクラス
   * @param {jQuery} $e - クラスを適用する jQuery オブジェクト
   * @return {boolean | string} - 適用されていたら true
   */
  static hasApplied(klass, $e) {
    let name = klass._classname || klass.prototype._classname;
    if (name === undefined || name === null) {
      return false;
    }
    return $e.data(name);
  };
}
