/**
 * @file ドロップダウンのactive付与
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname Dropdown
 * @classdesc ドロップダウン
 */
export default class Dropdown {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Dropdown.constructor');
		this._$e = $e;
		this._$target = $(`#${$e.attr('aria-controls')}`);
		let et = $e.attr('data-event-type');
		console.log('et::' + et);

		if(et == 'hover') {
			this._$target.on('mouseover', () => this.onMouseup());
			this._$target.on('mouseout', () => this.onMousedown());
			$e.on('click', () => this.onClick());
		}else {
			$e.on('click', () => this.onClick());	
		}
		
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof Dropdown
	 * @return {undefined}
	 */
	onClick() {
		console.log('Dropdown.onClick');
		//const remove_class = $(`.${this._$e.data('remove-class')}`);
		let isActive = this._$target.hasClass('show');
		//remove_class.removeClass('show');
		// if(!isActive){
		// 	this._$target.toggleClass('show');
		// }
		this._$target.toggleClass('show');
	};

	/**
	 * mouseupのコールバック
	 *
	 * @memberof Dropdown
	 * @return {undefined}
	 */
	 onMouseup() {
		console.log('Dropdown.onMouseup');
		this._$target.addClass('show');
	};
	/**
	 * mousedownのコールバック
	 *
	 * @memberof Dropdown
	 * @return {undefined}
	 */
	 onMousedown() {
		console.log('Dropdown.onMousedown');
		this._$target.removeClass('show');
	};
}
