/**
 * @file 金額シミュレーション
 *
 */
'use strict';
import Common from '../../Utils/productConst';

/**
 * @classname PriceCompare
 * @classdesc 金額シミュレーション
 */
export default class PriceCompare {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('PriceCompare.constructor');
		this._$e = $e;
		$e.on('change', () => this.onChange());
	};

	/**
	 * changeのコールバック
	 *
	 * @memberof PriceCompare
	 * @return {undefined}
	 */
	onChange() {
		console.log('PriceCompare.onChang');

		const productId = parseInt($('#setName option:selected').attr('data-product-id'));
		console.log('productId::' + productId);
		const productInfo = window.danitori.const.PRODUCT_INFO[productId];
		console.log('productInfo::' + productInfo);
		const productPrice = productInfo['price'];
		console.log('productPrice::' + productPrice);
		const exchangeInfo = productInfo['exchange'];
		console.log('exchangeInfo::' + exchangeInfo);

		$('#sendPrice').html('648');

		getUpcellId(productId);

		$('#normalPriceFirst').html(addFigure(productPrice));
		if(
			productId == 80006 ||
			productId == 80016 ||
			productId == 90011 ||
			productId == 90012 ||
			productId == 90013 ||
			productId == 90026 ||
			productId == 90018 ||
			productId == 90037 ||
			productId == 90015 
		){
			$('#normalPriceSecond').html(addFigure(productPrice));
		} else {
			getExchangePrice(exchangeInfo);
		}
		sum(productId);
	}
}

function addFigure(str) {
	var num = new String(str).replace(/,/g, "");
	console.log('addFigure num ::' + num);
	while(num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));

	console.log('addFigure num2 ::' + num);
	return num;
}

// 通常の詰替え用製品の値段を取得
function getExchangePrice(exchangeInfo){
	const productInfo = window.danitori.const.PRODUCT_INFO[exchangeInfo];
	console.log('getExchangeInfo::' + productInfo);
	// console.log(productInfo);
	const exchangePrice = productInfo['price'];
	console.log('選択された商品の詰め替え商品の値段は ' + exchangePrice + ' 円です');
	$('#normalPriceSecond').html(addFigure(exchangePrice));
}

// upcell商品のIDを取得
function getUpcellId(productId){
	const productInfo = window.danitori.const.PRODUCT_INFO[productId];
	const upcellId = productInfo['upcell'];
	console.log('選択された商品の定期セットのIDは ' + upcellId + ' です');
	getUpcellInfo(upcellId);
}

// upcell商品の初回の値段と2回目以降の値段を取得してhtmlに入れる
function getUpcellInfo(upcellId){
	var upcellInfo = window.danitori.const.PRODUCT_INFO[upcellId];
	var upcellPrice = upcellInfo['price'];
	var upcellPrice2 = upcellInfo['price2'];
	console.log('選択された商品の定期セット初回の値段は ' + upcellPrice + ' 円です');
	console.log('選択された商品の定期セット2回目以降の値段は ' + upcellPrice2 + ' 円です');
	$('#subscriptionPriceFirst').html(addFigure(upcellPrice));
	if(upcellPrice2 == ''){
		$('#subscriptionPriceSecond').html(addFigure(upcellPrice));
	} else {
		$('#subscriptionPriceSecond').html(addFigure(upcellPrice2));
	}
}

function sum(productId){
	//- 通常価格の合計
	console.log (productId);
	const normalPriceFirst = parseInt($('#normalPriceFirst').html().split(',').join(''));
	const normalPriceSecond = parseInt($('#normalPriceSecond').html().split(',').join('') * 3);
	const normalPostage = parseInt($('#sendPrice').html() * 4);
	console.log(normalPostage);
	const normalSum = parseInt((normalPriceFirst + normalPriceSecond + normalPostage));
	//- 定期価格の合計
	const subscriptionPriceFirst = parseInt($('#subscriptionPriceFirst').html().split(',').join(''));
	const subscriptionPriceSecond = parseInt($('#subscriptionPriceSecond').html().split(',').join('') * 3);
	const subscriptionPriceSum = parseInt((subscriptionPriceFirst + subscriptionPriceSecond));
	//- for debug
	// console.log(normalPriceFirst);
	// console.log(normalPriceSecond);
	// console.log(normalPostage);
	// console.log(subscriptionPriceSum);
	$('#normalPriceSum').html(addFigure(normalSum));
	$('#subscriptionPriceSum').html(addFigure(subscriptionPriceSum));
	const point = Math.floor(subscriptionPriceSum * 0.02);
	$('.pointDiscount').html(point);
	var differencePrice = (normalSum - (subscriptionPriceSum + point));
	$('.differencePrice').html(differencePrice);
	var differencePrice = (normalSum - subscriptionPriceSum);
	console.log('differencePrice::' + differencePrice.toLocaleString());
	let message = '定期セットの注文の場合<span class="differencePrice">' + differencePrice.toLocaleString() + '</span>円お得です!!';
	// let message = '<br class="visible-xs">定期セットの注文の場合<span class="pointDiscountText">2%ポイント還元分</span><span class="pointDiscount">' + point.toLocaleString() + '</span>円と合わせて<br class="visible-xs"><span class="differencePrice">' + (differencePrice + point).toLocaleString() + '</span>円お得です!!';
	// if(productId == 90005 || productId == 90002 || productId == 90003 ){
		// message = '定期セットの注文の場合<span class="differencePrice">' + differencePrice.toLocaleString() + '</span>円お得です!!';
	// }
	console.log('message::' + message);
	//$('.compareRresult').html(message);
	$('.differencePrice').html(differencePrice.toLocaleString());
}

