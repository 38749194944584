/**
 * @file toggle付与
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname Toggle
 * @classdesc Toggle
 */
export default class Toggle {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Toggle.constructor');
		this._$e = $e;
		this._$target = $(`#${this._$e.data('toggle-target')}`);
		$e.on('click', () => this.onClick());
	};


	/**
	 * clickのコールバック
	 *
	 * @memberof Toggle
	 * @return {undefined}
	 */
	onClick() {
		console.log('Toggle.onClick');
		this._$target.toggleClass('toggle');
	}
}
