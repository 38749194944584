/**
 * @file LpIframe付与
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname LpIframe
 * @classdesc LpIframe
 */
export default class LpIframe {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('LpIframe.constructor');
		this._$e = $e;

		const resizeIframe = () => {
			try {
				// iframeの高さを計算
				const height = this._$e[0].contentWindow.$('html').height();
				this._$e.css({
					'width': '100%',
					'height': height
				});
				console.log('height:: ' + height);
			} catch (error) {
				console.error('Error resizing iframe:', error);
			}
		};

		// iframeのロード完了後にリサイズを行う
		this._$e.on('load', () => {
            setTimeout(resizeIframe, 3000);
        });

		// ウィンドウのリサイズ時にもリサイズを行う
		$(window).on('resize', resizeIframe);

		// 初回ロード時にリサイズをトリガー
		this._$e.trigger('load');

		$(window).on('message', (event) => {
            const eventData = event.originalEvent;
            const sameOrigin = window.location.origin;

            // 同じドメインの場合はオリジンのチェックを行わない
            if (eventData.origin !== sameOrigin) return;

            const height = eventData.data;
            this._$e.css('height', height + 'px');
        });
	};
}
