/**
 * @file Accordion
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname Accordion
 * @classdesc アコーディオン
 */
export default class Accordion {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Accordion.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof Accordion
	 * @return {undefined}
	 */
	 onClick() {
		console.log('Accordion.onClick');
		let height = 0;
		if( this._$e.prevAll('dt').hasClass('open')){
			height = this._$e.prevAll('dt.open').next('dd').height();
		}

		this._$e.next('dd').slideToggle();
		this._$e.next('dd').siblings('dd').slideUp();
		this._$e.toggleClass('open');
		this._$e.siblings('dt').removeClass('open');

		const target = this._$e.offset().top;
		const viewPortTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		const maginTop = $(window).width() >= 768 ? 140 : 0;

		if(target - height - maginTop < viewPortTop) {
			const position = target - height - maginTop;
			$('body,html').stop().animate({scrollTop:position}, 500, 'swing');
		}

	};
}
