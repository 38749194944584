/**
 * @file Popup
 */
'use strict';

//import Common from "../../Utils/Common";

/**
 * @classname Popup
 * @classdesc Popup
 */
export default class Popup {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Popup.constructor');
		this._$e = $e;
		this._$target = $(`#${$e.attr('data-target')}`);
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof Popup
	 * @return {undefined}
	 */
	 onClick() {
		console.log('Popup.onClick');
		const type = this._$e.attr('data-event-type');

		if(type == 'open'){
			this._$target.addClass('show');
			$('.block--overlay').addClass('show');
		}else if(type == 'close'){
			this._$target.removeClass('show');
			$('.block--overlay').removeClass('show');
		}

	};
}
