/**
 * @file Utils/Listener.js
 */

/**
 * @classname Listener
 * @classdesc Observer / Listener 系、on off trigger処理
 */
export default class Listener {
  constructor() {
    this._function = {};
  }

  /**
   * on
   *
   * @memberOf Listener
   * @param {string} name - イベント名
   * @param {function} fn - 関数
   * @returns {object} - Listener インスタンス
   */
  on(name, fn) {
    this._function[name] = this._function[name] || [];
    this._function[name].push(fn);

    return this;
  }

  /**
   * off
   *
   * @memberOf Listener
   * @param {string} name - イベント名
   * @param {function} fn - 関数
   * @returns {object} - Listener インスタンス
   */
  off(name, fn) {
    let functions = this._function[name];
    if (!functions) {
      return this;
    }
    for (let i = 0, l = functions.length; i++;) {
      let _fn = functions[i];
      if (fn === _fn) {
        functions.splice(i, 1);
        break;
      }
    }
    return this;
  }

  /**
   * Trigger
   *
   * @param {string} name - 送信するイベント名
   * @param {object} - Listener インスタンス名
   */
  trigger(name) {
    console.info(`trigger: ${name}`);
    let functions = this._function[name] || [];
    for(let i = 0, l = functions.length; i < l; ++i) {
      let fn = functions[i];
      if (fn) {
        fn.apply(this, arguments);
      }
    }
    return this;
  }


}
