/**
 * @file たまごカートへ遷移
 *
 */
 'use strict';
 //import Common from '../../Utils/productConst';
 
 /**
  * @classname ProductModal
  * @classdesc たまごカートへ遷移
  */
 export default class ProductModal {
	 /**
	  * @constructor
	  * @param {jQuery} $e - 該当する jQuery オブジェクト
	  */
	 constructor($e) {
		 console.log('ProductModal.constructor');
		 this._$e = $e;
		 this._$target = $(`#${$e.attr('data-form-id')}`);

		 $e.on('click', () => this.onClick());
	 };
 
	 /**
	  * changeのコールバック
	  *
	  * @memberof ProductModal
	  * @return {undefined}
	  */
	  onClick() {
		console.log('ProductModal.onClick');

		const productId = this._$e.attr('data-product-id');
		const formId = `#${this._$e.attr('data-form-id')}`;
		console.log('formId::' + formId);


		$(formId).submit();
		//gaSend('' , formId, productId);

	}
}

function gaSend(btnData, formId, productID) {
	//var formID = (btnLocation == 'NoModal') ? '#cartFormNoModal' : '#cartForm';
	if ( btnData == undefined ) btnData ='';
	console.log('productID::' + productID);
	console.log('formId::' + formId);
	ga('send', 'event', {
		'eventCategory': 'addCart',
		'eventAction'  : 'click',
		'eventLabel'   : 'addCart' + productID + btnData,
		'hitCallback'  : function () {
			$(formId).submit();
		}
	});
}